import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { UserContext } from './context/UserContextProvider';
import Clock from './pages/tools/Clock';
import './Header.css'; // Assuming you have a CSS file for additional styles

const Header = () => {
  const location = useLocation();
  const { logoutUser, isLoggedIn, currentStep, clientUrlRoot, testUnderway, isLeapFrogTest, isSampleTest, accessCode, getApiConfig, switchTest } = useContext(UserContext);
  console.log("isLeapFrogTest:", isLeapFrogTest);

  const [tests, setTests] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const handleLogout = () => {
    logoutUser();
  };

  useEffect(() => {
    console.log("headUE1");
    const fetchTests = async () => {
      try {
        const response = await fetch(getApiConfig('getActiveTestsBySiteAccessCode'), {
          method: 'POST',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
          body: JSON.stringify({
            'access-code': accessCode,
          }),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        setTests(data);
      } catch (error) {
        console.error('Error fetching tests:', error);
      } finally {
        setIsLoading(false);
      }
    };

    if (accessCode) {
      fetchTests();
    }
  }, [accessCode, getApiConfig]);

  const extractDisplayName = (rawName) => {
    const regex = /^[a-zA-Z]+\s\d+\s(.*?)\sTemplate/;
    const match = rawName.match(regex);
    return match ? match[1] : rawName;
  };

  const handleSelect = (eventKey) => {
    switchTest(eventKey);
  };

  return (
      <>
        <Navbar collapseOnSelect expand="md" variant="ccts">
          <LinkContainer to={isSampleTest !== true ? "/dashboard" : currentStep}>
            <Navbar.Brand href="/">
              {isLoggedIn !== true && (
                  <img
                      src="/UlogoHv1_RGB_40x142.jpeg"
                      height="40"
                      width="142"
                      className="d-inline-block align-top"
                      alt="University of Utah"
                      style={{ marginRight: '10px', marginTop: '-5px', marginBottom: '-5px' }}
                  />
              )}
              CPOE/EHR Evaluation Tool
            </Navbar.Brand>
          </LinkContainer>
          <Navbar.Toggle />
          <Navbar.Collapse variant="light">
            <Nav className="mr-auto custom-nav">
              {isLoggedIn && isSampleTest !== true && <LinkContainer to="/dashboard"><Nav.Link>Dashboard</Nav.Link></LinkContainer>}
              {isLoggedIn && isSampleTest !== true && <LinkContainer to="/instructionspdf"><Nav.Link>Instructions</Nav.Link></LinkContainer>}
              {isLoggedIn && !isLeapFrogTest && <LinkContainer to={currentStep}><Nav.Link>Current Step</Nav.Link></LinkContainer>}
              {(isLoggedIn) && (isLeapFrogTest) ?
                  <LinkContainer to="/sampledashboard"><Nav.Link>Take Sample Test</Nav.Link></LinkContainer>
                  : ""
              }
              {isLeapFrogTest && <a className="nav-link" href="https://leapfroghelpdesk.zendesk.com/" target="_blank" rel="noopener noreferrer">Contact the Leapfrog Help Desk</a>}
              {(() => {
                // We'll check each piece of the condition and log a message if it fails.
                if (!isLoggedIn) {
                  console.log("Available Tests dropdown hidden because: user is NOT logged in.");
                  return null;
                }
                if (isLeapFrogTest) {
                  console.log("Available Tests dropdown hidden because: this is a LeapFrog test.");
                  return null;
                }
                if (testUnderway === true) {
                  console.log("Available Tests dropdown hidden because: a test is currently underway.");
                  return null;
                }
                if (isSampleTest === true) {
                  console.log("Available Tests dropdown hidden because: this is a sample test.");
                  return null;
                }
                const hiddenPaths = ['/viewresults', '/viewhistoricalresults', '/instructionspdf'];
                if (hiddenPaths.includes(location.pathname)) {
                  console.log("Available Tests dropdown hidden because: current location pathname is in hiddenPaths.");
                  return null;
                }

                // If all conditions pass, render the dropdown:
                return (
                    <NavDropdown
                        title="Available Tests"
                        id="basic-nav-dropdown"
                        className="custom-dropdown"
                        onSelect={handleSelect}
                        disabled={isLoading || tests.length === 0}
                    >
                      {tests.length === 0 ? (
                          <NavDropdown.Item>No tests available</NavDropdown.Item>
                      ) : (
                          tests.map((test) => (
                              <NavDropdown.Item
                                  key={test.access_code}
                                  eventKey={test.access_code}
                                  active={test.access_code === accessCode}
                                  onClick={(e) => {
                                    if (test.access_code === accessCode) {
                                      e.preventDefault(); // Prevent action if it's the current test
                                    } else {
                                      switchTest(test.access_code);
                                    }
                                  }}
                              >
                                {extractDisplayName(test.test_name)}
                                {test.role_display_name && ` (${test.role_display_name})`}
                              </NavDropdown.Item>
                          ))
                      )}
                    </NavDropdown>
                );
              })()}


            </Nav>
            <Nav className="ml-auto">
              {isLoggedIn && testUnderway && !isSampleTest ? <Clock /> : ''}
              {isLeapFrogTest ? '' : isLoggedIn ? (
                  <LinkContainer to="/login"><Button onClick={handleLogout} className="px-3" variant="outline-light">Logout</Button></LinkContainer>
              ) : (
                  <Nav.Link href="mailto:Helpdesk_EHRSafetyEvalTool@utah.edu?subject=EHR Safety Eval Tool&body=Hello, I need some help with...">Need Assistance? Contact Info</Nav.Link>
              )}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </>
  );
};

export default Header;
