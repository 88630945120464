import React, { useContext, useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Button from 'react-bootstrap/Button';
import 'jspdf-autotable'
import { UserContext } from "../context/UserContextProvider";
import { useHistory } from "react-router-dom";
import { sortOrders } from '../globals'
import MedRecBox from "./tools/MedRecBox";
import PatientBoxLF2023 from "./tools/PatientBoxLF2023";
import PatientBoxLF2024 from "./tools/PatientBoxLF2024"
import PatientBoxLF2025 from "./tools/PatientBoxLF2025"
import PatientBox2022 from "./tools/PatientBox2022"
import Reminder from '../pages/tools/Reminder'
import RedText from "./tools/RedText";
import useDisableBackButton from "./hooks/useDisableBackButton";
// import useSetCurrentStep from "./hooks/useSetCurrentStep";

/* 
 * IF YOU MAKE A CHANGE HERE IN THE SURVEY CLIENT, MAKE SURE TO MAKE THE SAME CHANGE IN THE ADMIN CLIENT *
 * cpoe_tools_2020/webclient/src/pages/widgets/pdfWidgets/DownloadObservSheetPDF.jsx                     *
 */

const TestPDF = () => {
    const { jsPDF } = require('jspdf'); 

    const {accessCode, getApiConfig, setCurrentStep, isLeapFrogTest, isSampleTest, deadlineDate, testType,
             testContact, testFacilityName, sampleTestDeadlineDate, currentTestStepNumber, setStepNumber, ttGroup, ttYear } = useContext(UserContext);

    const [unsortedOrders, setUnsortedOrders] = useState([]);
    const [orders, setOrders] = useState([]);
    const [pdfOrders, setPdfOrders] = useState([]);
    const history = useHistory();

    // timestamp for naming observation sheet
    let dateString = new Date().toDateString().split(' ').join('_');

    useDisableBackButton()  // Disable the browser's back button 
    
    // 7/15/22 -> Setting the current step(which starts the client-side test timer) 
    // should happen AFTER fetching patients data(as this action sets the start time in the database)
    // best course of action is to have that code run here instead of in the custom hook so when it runs 
    // is easier to control -- function now runs in an effect once order data is fetched

    useEffect(() => {
        if (unsortedOrders.length === 0) {
            //on mount fetch orders for Observation Sheet PDF
            fetch(getApiConfig("getSiteTestOrders"), {
                method: "POST",
                mode: "cors",
                headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
                },
                body: JSON.stringify({
                    "access-code": accessCode,
                }),
            })
            .then((response) => response.json())
            .then((data) => {
                let rawData = JSON.parse(decodeURIComponent(data.data));
                // console.log(`getSiteTestOrders RESPONSE: ${rawData}`);
                // save the data in state, PDF not created until requested by the button
                setUnsortedOrders(rawData[1].orders);
            })
            .catch((error) => {
                console.error("Error:", error);
            });
        }
    }, [accessCode, getApiConfig, unsortedOrders])

    // replacing the usual run of useSetCurrentStep("ORDER_PDF_REQUESTED", 3) // setting step to 3 resets test clock
    useEffect(() => {
      if (unsortedOrders.length > 0) {
          const setStepOneAfterPatientsAreDownloaded = (step, stepNum) => {
            // sets current step, if successful sets step number for UI/Context
              // console.log('running set step one function...');
              if (currentTestStepNumber !== stepNum && !isSampleTest) {
                  fetch(getApiConfig("updateCurrentSiteTestStep"), {
                      method: "POST",
                      mode: "cors",
                      headers: {
                          "Content-Type": "application/json",
                          "Accept": "application/json"
                      },
                      body: JSON.stringify({
                          "access-code": accessCode,
                          "ui-step": step
                      })})
                      .then((response) => response.json())
                      .then((data) => {
                      if (data.response.includes('SUCCESS')) {
                        // setting step number will check and set time for test clock  
                        setStepNumber(stepNum)
                        console.log('setting context step # to ', stepNum);
                      }
                      })
                      .catch((error) => {
                      console.error("Error setting step 3:", error);
                      });
              }
          }
          // Note that the outer function will run twice, but the inner function and fetch only happen once
          setStepOneAfterPatientsAreDownloaded("ORDER_PDF_REQUESTED", 3)
      }
  }, [accessCode, currentTestStepNumber, getApiConfig, isSampleTest, unsortedOrders, setStepNumber])

    useEffect(() => { // record_id is a string, redcap_repeat_instance is a number
      if (unsortedOrders.length !== 0){ // condition guards against sorting an empty array when state is created
        let sorted = sortOrders(unsortedOrders);
        // filter non-medrec orders, medrec observation orders, and leave med rec prior & discharge
        //TODO: normal categories and medrec categories check needs abstracting to one common JS library!!!!
        let sortedPatientOrders = sorted.filter(o => Number(o.order_category_name) <= 14 || o.order_category_name >= 18)
        let sortedMedRecOrders = sorted.filter(o => Number(o.order_category_name) === 17)
        let combinedOrders = [...sortedPatientOrders, ...sortedMedRecOrders]
          setOrders(combinedOrders)
      }  
    }, [unsortedOrders])

    useEffect(() => {
        const assignPatientIndex = () => {  // uncomment below (and comment out line 109) to assign order number per patient in the test
            let newOrdersArray = [...orders]
            let patientNum = parseInt(newOrdersArray[0].record_id);
            let patientIndex = 1;
            // let orderIndex = 1;
            for (let i = 0; i < newOrdersArray.length; i++) {
                if (parseInt(newOrdersArray[i].record_id) !== patientNum) {
                    patientIndex += 1;
                    patientNum = parseInt(newOrdersArray[i].record_id);
                    // console.log('index in loop', patientIndex, 'and patientNum:', patientNum ,' and the record_id: ', parseInt(newOrdersArray[i].record_id));
                    newOrdersArray[i]['display_new_patient'] = true // Adding boolean allows for 'separator'
                    newOrdersArray[i]['patient_id_test'] = patientIndex;
                    // orderIndex = 1;
                    // newOrdersArray[i]['order_id_test'] = orderIndex;
                    // orderIndex++;
                } else {
                    newOrdersArray[i]['patient_id_test'] = patientIndex;
                    // newOrdersArray[i]['order_id_test'] = orderIndex;
                    // orderIndex++;
                }
                newOrdersArray[i]['order_id_test'] = (i + 1); // for numbering orders sequentially instead of per patient 
            }
            // console.log('new orders array with id/indexes', newOrdersArray);
            setPdfOrders(newOrdersArray);
            
        }
        if (orders.length !== 0) {
            assignPatientIndex();
        }
    }, [orders])

    // for function, add offset value for placing elements
    
    // EDGE CASE:
    // conditionally label the first med rec order if it's the second order on a page
    let labelledFirstMedRec = true;
    const addPatientBox = (doc, o, offset) => {  
      if (Number(o.order_category_name) === 17 && labelledFirstMedRec && offset > 150) {
        return labelAndMedRecBox(doc, o, offset)
      } else if (Number(o.order_category_name) === 17 && labelledFirstMedRec) {
        return medRecNoLabelNecessary(doc, o, offset)
      } else if (Number(o.order_category_name) === 17) { // THIS WILL NEED TO BE REFACTORED
        return MedRecBox(doc, o, offset)
      } else {
        if (ttGroup === "LF" && Number(ttYear) >= 2025) {
          return PatientBoxLF2025(doc, o, offset)
        } else if (ttGroup === "LF" && Number(ttYear) === 2024) {
          return PatientBoxLF2024(doc, o, offset)
        } else if (ttGroup === "LF" && Number(ttYear) === 2023) {
          return PatientBoxLF2023(doc, o, offset)
        } else {
          // console.log("old patientbox was called")
          return PatientBox2022(doc, o, offset)
        }
      }
    }


    // Offsets for two boxes: 20, 385
    const newPage = (doc) => {
        doc.addPage();
    }


    const medRecNoLabelNecessary = (doc, o, offset) => {
      MedRecBox(doc, o, offset)
      labelledFirstMedRec = false;
    }

    const labelAndMedRecBox = (doc, o, offset) => {
      let mRTitle1 = `Using your EHR's medication reconciliation function, please record any advice or information the `;
      let mRTitle2 = `prescriber receives during or after entering each medication order.`; 
      doc.setFontSize(12);
      doc.text(mRTitle1, 20, 401);
      doc.text(mRTitle2, 20, 415);
      MedRecBox(doc, o, offset)
      labelledFirstMedRec = false;
    }

    const secondBoxAndNewPage = (doc, order) => {
        addPatientBox(doc, order, 390);
        newPage(doc)
    }

    // function for adding a cover page (LeapFrog only) 
    const CoverSheetAndAddPage = (doc, hospitalNameFooter) => {

        doc.setFontSize(24)
        doc.text(`Orders and Observation Sheet`, (doc.internal.pageSize.width / 2), 30, {align: 'center'})
        doc.setLineWidth(3);
        doc.line(30, 38, 565, 38)
        doc.setFontSize(15)
        doc.setFont('helvetica', 'bold');
        doc.text(`${isSampleTest ? 'Sample ' : ''}Adult ${testType} Test`, 48, 60)
        doc.setFont('helvetica', 'normal');
        doc.setFontSize(9)
        doc.text(`You have until ${isSampleTest ? sampleTestDeadlineDate : deadlineDate} EDT to complete steps 3 - 6.`, 48, 74)
        doc.text(`${hospitalNameFooter}`, 48, 90)
        doc.text(`Instructions for the test are available at: http://www.leapfroggroup.org/survey-materials/survey-and-cpoe-materials`, 48, 132)
        doc.text(`Review the instructions and requirements completely before taking the test and have them available while completing each step.`, 48, 158)
        doc.setLineWidth(1);

        doc.addPage();
    }

    // originally addPageNumbers
    // modified to include hospital name in footer, in case loose sheets of paper for multiple sites are scattered on the floor
    // hospitalNameFooter was also abstracted out, as CoverSheetAndAddPage also uses it
    const addFooter = (doc, hospitalNameFooter) => {
      const pages = doc.internal.getNumberOfPages();
      const pageWidth = doc.internal.pageSize.width;  //Optional
      const pageHeight = doc.internal.pageSize.height;  //Optional
      doc.setFontSize(10);  //Optional
              
      for (let j = 1; j < pages + 1 ; j++) {
          let horizontalPos = pageWidth / 2;  //Can be fixed number
          let verticalPos = pageHeight - 10;  //Can be fixed number
          doc.setPage(j);
          if (j === 1 && isLeapFrogTest) { // update the number of pages on cover sheet at the end of pdf creation
            doc.setFontSize(9);
            doc.text(`This document is ${pages} pages long.`, 48, 106);
            doc.setFontSize(10);
          }
          doc.text(`${hospitalNameFooter}`, horizontalPos, verticalPos - 12, {align: 'center'});
          doc.text(`${j} of ${pages}`, horizontalPos, verticalPos, {align: 'center'});
      }
  } 

    // Paper size in points:
    // Default (A4)		 595x842
    const createTestPDF = (date) => {
      
        const doc = jsPDF('p', 'pt');

        const hospitalNameFooter = `Generated for: ${testContact !== "" ? (testContact + ` at `) : ''}${testFacilityName !== null ? testFacilityName : 'Hospital Name'}`

        if (isLeapFrogTest) {CoverSheetAndAddPage(doc, hospitalNameFooter)}
        // setTitle(doc)
        // iterating through the test

        pdfOrders.map( (order, i) => {
            if (i === pdfOrders.length - 1 && i % 2 !== 0) {
                return addPatientBox(doc, order, 390);
            } else if (i % 2 !== 0) {
                    return secondBoxAndNewPage(doc, order)
            } else {
                return addPatientBox(doc, order, 20);
            }
        })

        addFooter(doc, hospitalNameFooter);
        doc.save(`EHR_EVALUATION_TEST_${date}.pdf`);
        // setup a timestamp (display to screen(timer in Nav?), but for CPOE store in context)
    }

    const handleBeginTest = () => { // yes, this function is poorly named...
        // set current step to next step
        setCurrentStep('/enterorders');
        // setStepNumber(4)
        // redirect to next step
        history.push('/enterorders');
    }

    const handleCreateTestPDF = () => {
      createTestPDF(dateString);
    }

    // Instructions that I think are only applicable to Ambulatory test
    const AmbulatoryInstructions = <><p className="mt-2">
    The orders in the Observation Sheet were randomly selected. Each is associated with one
    of the test patients set up in the prior steps of the assessment.
    Print this document and have a physician who routinely uses CPOE to
    enter the orders into your CPOE system for the appropriate test
    patients. This worksheet should be used to record observations
    during the test. Record any advice or information received, then continue 
    the assessment to complete the online submission and
    submit your results. You should only record advice or information
    received by the physician entering orders (i.e., advice or
    information sent to a pharmacist or other type of user is not to be
    recorded on this answer sheet.)
    <br /><br />
    Be sure to read detailed instructions for the test in advance. Key
    points to remember:</p>
    <ul>
      <li>
        There is a time limit of 3 hours for completing this portion of
        the test.
      </li>
      <li>
        Enter <em>and</em> sign each order (or order pair) below for the
        specified test patient in the customary way physicians in this
        hospital order the medication(s), using structured orders (order
        sentences) or medication order sets as appropriate.
      </li>
      <li>
        Record any advice or information presented, or restrictions on
        ordering the test order(s), as guided by the Observation Sheet. This
        includes when a dose, frequency, or route in the test order is
        not available (selectable). Test orders include normal orders,
        so note also when no advice, information or restrictions are
        provided.
      </li>
      {/* <li>
        If the medication is not available to be ordered, choose
        "Medication not electronically orderable in any formulation". If the medication is available
        (on formulary), but the route is not, choose "Received advice,
        information, or restrictions" and "Can't enter route (not
        selectable)".
      </li> */}
      <li>
        Once you have recorded observations for each medication (or pair of
        medications) ordered, be sure to discontinue the order before
        proceeding to the next test order.
      </li>
    </ul>
    </>;

  //   // LeapFrog warning Card        ---> LF requested removing this warning on 1/5/22
  //   const LeapFrogWarning = <Card className="mt-2">
  //   <Card.Body>
  //     You must submit and affirm your responses by <strong>{deadline} EDT</strong>. If you fail to complete <strong>Steps 3-6</strong> within the time limits, the test will be scored as "Incomplete Evaluation" and you will not be able to take this test again for 120 days.
  //     {/* REMOVED: 12/8/21 Once you have started the Test, the timer counts down even when you are not logged into the Tool. */}
  //   </Card.Body>
  // </Card>


    const buttonStyle = {
      marginTop: '1em',
      fontWeight: '300'
    }

    return (
      <Container className="mt-3 pt-3 mb-5 pb-3" style={{ fontWeight: "300" }}>
        <Row>
          <Col sm={12}>
            <h1>Step 3 of 7: Download &#38; Print Orders and Observations Sheet</h1>
            <hr />
            <Reminder />
            {testType === 'Outpatient' ? AmbulatoryInstructions : ''}
            <p>
            Click “Download and Print Orders and Observations Sheet PDF” to download the Orders and Observations Sheet. Once you confirm that the PDF has downloaded, click “Proceed to Step 4.”
            </p>
            {/* {isLeapFrogTest && LeapFrogWarning} */}
            {/* LF requested removal 1/5/22 */}
            <RedText />
            <div style={{ textAlign: "right" }}>
                {unsortedOrders.length > 0 ?
                    <Button
                        style={buttonStyle}
                        onClick={handleCreateTestPDF}
                        className="px-3 text-nowrap"
                        variant="primary"
                    >
                        Download and Print Orders and Observations Sheet PDF
                    </Button>
                    :
                    <Button
                        disabled
                        style={buttonStyle}
                        className="px-3 text-nowrap"
                        variant="primary"
                    >
                        Awaiting Orders and Observations Data
                    </Button>
                }


              <Button
                style={buttonStyle}
                onClick={handleBeginTest}
                className="px-3 ml-2"
                variant="info"
              >
                Proceed to Step 4
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    );
}


export default TestPDF